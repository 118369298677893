import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './aboutUs.css';

const sections = [
  {
    title: 'Historia',
    content: `La semilla de Afilados Rosa fue plantada en un taller de luthería, donde cada herramienta debía estar perfectamente afilada para moldear instrumentos musicales con la máxima precisión. Este meticuloso cuidado y amor por el trabajo manual me llevó a expandir mis habilidades hacia el afilado de todo tipo de herramientas y cuchillos. Con el tiempo, me di cuenta de que tanto chefs profesionales como artesanos y entusiastas del bricolaje buscaban la misma perfección en sus herramientas que un músico busca en su instrumento. Así nació Afilados Rosa, con el compromiso de elevar cada corte a un acto de precisión y arte.`,
  },
  {
    title: 'Filosofía de servicio',
    content: `En Afilados Rosa, creemos que un buen afilado es esencial para el éxito en cualquier cocina, taller o estudio. Nuestras técnicas integran lo mejor de los mundos antiguo y moderno, por ello adoptamos métodos de afilado al agua que preservan la integridad de los metales, evitando el sobrecalentamiento y manteniendo el filo más tiempo. Esta técnica, combinada con el uso de ruedas CBN para un afilado en seco equivalente, asegura que cada herramienta no solo se afile con precisión, sino que también conserve su calidad y durabilidad. Nuestra elección de técnicas refleja un compromiso con la excelencia y la innovación, garantizando que cada herramienta sea devuelta en su mejor condición.`,
  },
  {
    title: 'Valores',
    content: `Nos guiamos por un compromiso inquebrantable con la calidad, la ética y la integridad. La satisfacción de nuestros clientes es nuestra prioridad principal, y nos esforzamos por superar sus expectativas con cada servicio. Ofrecemos una política de garantía de reafilado de 7 días para asegurar la completa satisfacción con nuestros resultados. Además, valoramos la transparencia en cada interacción, asegurando que nuestros clientes siempre estén informados y satisfechos con el proceso y los resultados.`,
  },
  {
    title: 'Tiempo de entrega',
    content: `El tiempo necesario para completar el servicio de afilado puede variar, pero estimamos que el proceso de afilado puede tomar una semana para trabajos estándar bajo condiciones normales de trabajo. Además, disponemos de tarifas que aseguran prioridad en el encargo, ideales para aquellos clientes que requieren una atención más rápida.

Servicio de Recogida y Entrega: Para mayor comodidad, ofrecemos un servicio de recogida y entrega en un lugar previamente acordado con el cliente. Este servicio está diseñado para facilitar el proceso y asegurar que tus herramientas sean manejadas con el cuidado y la atención que requieren.

Con nuestro compromiso de eficiencia y atención al cliente, nos aseguramos de que tus herramientas no solo sean afiladas con precisión, sino también manejadas y entregadas con la máxima conveniencia para ti.`,
  },
];

export default function IndexSectionAboutUs6() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <React.Fragment>
      <>
        <section id="aboutus" className="py-12 bg-gray-50 md:py-16">
          <div className="container mx-auto px-4">
            <h1 className="font-heading text-6xl md:text-10xl mb-8 tracking-tightest">Sobre nosotros</h1>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-3/12 xl:w-2/12 px-4 mb-14 lg:mb-0">
                <a
                  className="group inline-flex items-center leading-none font-medium pb-2 border-b-2 border-black"
                  href="#"
                >
                  <span className="mr-4">Conócenos</span>
                  <span className="group-hover:rotate-45 transform transition duration-100">
                    <svg
                      width={11}
                      height={11}
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 1.5L1.5 9.5"
                        stroke="black"
                        strokeWidth="1.3"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.5 8.83571V1.5H2.16429"
                        stroke="black"
                        strokeWidth="1.3"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className="w-full lg:w-9/12 xl:w-10/12 px-4">
                <div className="lg:max-w-5xl lg:ml-20 lg:-mt-12">
                  <p
                    className="text-xl xs:text-2xl tracking-tight mb-10"
                    style={{ textIndent: '8rem' }}
                  >
                    <span>
                      En rosafilados, unimos el arte del afilado con la
                      precisión de la luthería para ofrecer a chefs, artesanos
                      y amantes de la cocina la maestría en el afilado que sus
                      herramientas merecen. Nuestra empresa nace de una pasión
                      por el detalle y un respeto profundo por las tradiciones
                      manuales, extendiendo el cuidado artesanal desde los
                      talleres de música hasta las cocinas más exigentes.
                    </span>
                    <span className="italic text-coolGray-600">
                      London, Chicago, Barcelona, Vienna, Rome, Las Vegas.
                    </span>
                  </p>
                  <ul>
                    {sections.map((section, index) => (
                      <li key={index} className="mb-8">
                        <div
                          className="flex items-center justify-between pb-2 border-b-2 border-black cursor-pointer"
                          onClick={() => toggleExpand(index)}
                        >
                          <span>{section.title}</span>
                          <span className="text-2xl">
                            {expandedIndex === index ? '-' : '+'}
                          </span>
                        </div>
                        <CSSTransition
                          in={expandedIndex === index}
                          timeout={300}
                          classNames="content"
                          unmountOnExit
                        >
                          <div className="mt-4">
                            <p>{section.content}</p>
                          </div>
                        </CSSTransition>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
