import React, { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

export default function IndexSectionHeaders1() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <section className="relative bg-gray-50 overflow-hidden" id='Home'>
        <nav className="bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="flex h-24 items-center justify-between">
              <a className="inline-block" href="#">
                <div className="text-4xl font-bold">
                  Afilados Rosa
                </div>
              </a>
              <button className="lg:hidden navbar-burger py-1" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <svg width={44} height={16} viewBox="0 0 44 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width={44} height={2} rx={1} fill="black" />
                  <rect y={14} width={44} height={2} rx={1} fill="black" />
                </svg>
              </button>
              <div className="hidden lg:flex ml-auto items-center space-x-6">
                <Link className="hover:underline cursor-pointer" to="home" smooth={true} duration={1000}>Inicio</Link>
                <Link className="hover:underline cursor-pointer" to="services" smooth={true} duration={1000}>Servicios</Link>
                <Link className="hover:underline cursor-pointer" to="faqs" smooth={true} duration={1000}>FAQs</Link>
                <Link className="hover:underline cursor-pointer" to="aboutus" smooth={true} duration={1000}>Sobre nosotros</Link>
                <Link className="group inline-flex py-2 px-4 items-center justify-center leading-none font-medium text-black hover:text-white border border-black rounded-full hover:bg-black transition duration-200 cursor-pointer" to="contactus" smooth={true} duration={1000}>
                  <span className="mr-2">Contacto</span>
                  <span className="group-hover:rotate-45 transform transition duration-100">
                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1L1 9" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9 8.33571V1H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </nav>
        <div className="container mx-auto px-4">
          <div id="home" className="relative mx-auto mb-12 pt-12 pb-64 xs:pb-40 lg:pb-12 border-b">
            <h1 className="font-heading text-4xl sm:text-6xl md:text-8xl lg:text-9xl tracking-tightest">
              Descubre el arte del corte perfecto
            </h1>
            <div className="absolute bottom-0 right-0 mb-10 max-w-sm ml-auto text-right">
              <p className="mt-10">
                <span className="block">Afilado y restauración de filos con técnicas de vanguardia.</span>
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-6/12 lg:w-7/12 px-3 relative">
              <div className="absolute top-0 right-0 w-44 h-44 mt-4 mr-5 flex items-center justify-center">
                <img className="absolute top-0 left-0 animate-spin" src="asitrastudio-assets/headers/spin-bonus.svg" alt="Spin Bonus" />
                <img className="relative" src="asitrastudio-assets/headers/arrow-spin.svg" alt="Arrow Spin" />
              </div>
              <img className="block w-full h-64 lg:h-80 rounded-t-3xl object-fill" src="asitrastudio-assets/headers/header-photo-horizontal.png" alt="Header Photo Horizontal" />
            </div>
            <div className="hidden md:block md:w-6/12 lg:w-5/12 px-3">
              <div className="flex flex-wrap -mx-3">
                <div className="w-6/12 px-3">
                  <img className="block w-full h-64 lg:h-80 rounded-t-4xl object-fill" src="asitrastudio-assets/headers/header-photo-small2.png" alt="Header Photo Small 2" />
                </div>
                <div className="w-6/12 px-3">
                  <img className="block w-full h-64 lg:h-80 rounded-t-4xl object-fill" src="asitrastudio-assets/headers/header-photo1.png" alt="Header Photo 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
            <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-50" onClick={() => setIsMenuOpen(false)} />
            <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto">
              <div className="flex mb-auto items-center">
                <a className="inline-block mr-auto" href="#">
                <div className="text-4xl font-bold">
                Afilados Rosa
              </div>
                </a>
                <button className="navbar-close" onClick={() => setIsMenuOpen(false)}>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6M6 6L18 18" stroke="#111827" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
              <div className="py-12 mb-auto">
                <ul className="flex-col">
                  <li className="mb-6"><Link className="inline-block cursor-pointer text-black" to="home" smooth={true} duration={1000} onClick={() => setIsMenuOpen(false)}>Inicio</Link></li>
                  <li className="mb-6"><Link className="inline-block cursor-pointer text-black" to="services" smooth={true} duration={1000} onClick={() => setIsMenuOpen(false)}>Servicios</Link></li>
                  <li className="mb-6"><Link className="inline-block cursor-pointer text-black" to="faqs" smooth={true} duration={1000} onClick={() => setIsMenuOpen(false)}>FAQs</Link></li>
                  <li><Link className="inline-block cursor-pointer text-black" to="contactus" smooth={true} duration={1000} onClick={() => setIsMenuOpen(false)}>Contacto</Link></li>
                </ul>
              </div>
           
            </nav>
          </div>
        )}
      </section>

      <style>
        {`
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .animate-spin {
            animation: spin 10s linear infinite;
          }
        `}
      </style>
    </React.Fragment>
  );
}
