import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function IndexSectionContact5() {

  const form = useRef();

  const [buttonText, setButtonText] = useState("Send message");
  const [buttonColor, setButtonColor] = useState("bg-black");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_fnzpxrm', 'template_wxe870e', form.current, 'BiysI58loHGQ9gUVR')
      .then(
        () => {
          console.log('SUCCESS!');
          setButtonText("Thanks for connecting with us");
          setButtonColor("bg-green-500");

          // Delay reset of the form to ensure button update
          setTimeout(() => {
            e.target.reset();
            setButtonText("Send message");
            setButtonColor("bg-black");
          }, 2000); // 2-second delay
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <React.Fragment>
      <>
        <section id="contactus" className="py-12 bg-gray-50 text-black md:py-20 ">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
                <div className="max-w-xl mx-auto lg:mx-0">
                  <div className="max-w-lg mb-10">
                    <h1 className="font-heading text-6xl xs:text-8xl sm:text-9xl xl:text-10xl tracking-tighter mb-8">Hablemos</h1>
                    <p className="text-xl text-coolGray-600">Si tienes alguna pregunta sobre nuestros servicios, no dudes en ponerte en contacto con nosotros. Estaremos encantados de ayudarte a mantener tus herramientas y cuchillos en perfecto estado.</p>
                  </div>
                  <div className="flex flex-wrap -mx-4 mb-20">
                    <div className="w-full sm:w-1/2 px-4 mb-6 sm:mb-0">
                      <div className="py-6 text-center border border-coolGray-300 rounded-3xl">
                        <span className="block mb-2 text-sm text-coolGray-500">Llamada</span>
                        <span className="font-medium">+34 651 818 399</span>
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 px-4">
                      <div className="py-6 text-center border border-coolGray-300 rounded-3xl">
                        <span className="block mb-2 text-sm text-coolGray-500">Email</span>
                        <span className="font-medium">contact@example.com</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="block mb-4 text-xl">Donde encontrarnos</span>
                    <p className="text-coolGray-600">A coruña ciudad</p>
                    <div className="max-w-md rounded-3xl border-2 border-white overflow-hidden">
                      <img className="block w-full" src="https://static.shuffle.dev/uploads/files/e1/e1f495b0a3993a1ce3f20e1b8ec55425ad756306/Captura-de-pantalla-2024-05-14-153452-png-1715693884757.webp" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="py-12 px-8 md:px-10 bg-white rounded-3xl max-w-xl mx-auto lg:mr-0">
                  <h3 className="text-3xl tracking-tight mb-8">Contactanos</h3>
                  <form onSubmit={sendEmail} ref={form} action="">
                    <input className="block w-full py-4 px-8 h-16 mb-6 text-coolGray-500 bg-white border border-coolGray-400 rounded-full" type="text" placeholder="Nombre" name="user_name" id=""   />
                    <input className="block w-full py-4 px-8 h-16 mb-6 text-coolGray-500 bg-white border border-coolGray-400 rounded-full" type="text" placeholder="Apellidos" name="user_apellidos" id="" />
                    <input className="block w-full py-4 px-8 h-16 mb-6 text-coolGray-500 bg-white border border-coolGray-400 rounded-full" name='user_email' type="email" placeholder="E-mail"  />
                    <input className="block w-full py-4 px-8 h-16 mb-6 text-coolGray-500 bg-white border border-coolGray-400 rounded-full" type="text" placeholder="Numero de contacto" name="user_number" id="" />
                    <div className="relative">
                      <span className="absolute top-1/2 right-0 mr-10 transform -translate-y-1/2">
                        <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.33203 1.66439L5.9987 6.33105L10.6654 1.66439" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      <select className="relative block w-full py-4 px-8 h-16 mb-6 text-coolGray-500 bg-transparent border border-coolGray-400 rounded-full appearance-none outline-none" name="user_worktype" id="">
                        <option selected="">What can we help you with?</option>
                        <option>Afilado de Cuchillos y Herramientas</option>
                        <option>Servicios de Restauración y Reparación de Cuchillos</option>
                        <option>Precios para Afilado Manual de Cuchillos (no incluir)
                        </option>
                      </select>
                    </div>
                    <textarea className="w-full h-52 py-5 px-8 text-coolGray-500 bg-white border border-coolGray-400 rounded-3xl resize-none mb-8" placeholder="Mensaje" rows={3} name="message" id="" defaultValue={""} />
                    <button className={`block w-full py-5 px-8 text-center font-medium text-white leading-none ${buttonColor} hover:bg-blue-500 rounded-full transition duration-150`} type="submit">{buttonText}</button>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

