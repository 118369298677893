import React from 'react';


export default function IndexSectionPricing9() {
  const pricingItems = [
    {
      title: "Afilado de Cuchillos y Herramientas",
      items: [
        { name: "Cuchillos Europeos", prices: ["Menos de 10 cm: 4.5€", "De 10 a 20 cm: 6.5€", "Más de 20 cm: 7€"] },
        { name: "Cuchillos Japoneses (incluye Santoku, Nakiri, etc.)", prices: ["Hasta 20 cm: 9€", "Más de 20 cm: 10€"] },
        { name: "Cuchillos de un solo bisel (especializados)", prices: ["Hasta 20 cm: 10€", "Más de 20 cm: 13€"] },
        { name: "Cuchillos Cerámicos", prices: ["Pequeños (hasta 15 cm): 12€", "Grandes (más de 15 cm): 15€"] },
        { name: "Navajas", prices: ["De bolsillo (hasta 7 cm): 5€", "Grandes (más de 7 cm): 7€"] },
        { name: "Cuchillos de monte o de caza", prices: ["Hasta 10 cm: 8€", "Más de 10 cm: 10€"] },
        { name: "Hachas", prices: ["Pequeñas: 8€", "Grandes: 10€"] },
        { name: "Cepillos de carpintero", prices: ["De 4 cm a 10 cm: 6€"] },
        { name: "Formones", prices: ["Pequeño (hasta 12 mm): 4€", "Mediano (entre 12 y 25 mm) o más: 6€", "Grande (más de 25 mm): 8€"] },
        { name: "Gubias", prices: ["Todas: 7€"] },
        { name: "Media luna de guarnicionero", prices: ["Pequeña (hasta 10 cm): 13€", "Mediana (hasta 15 cm): 16€", "Grande (más de 15 cm): 20€"] },
        { name: "Chiflas", prices: ["Pequeña (hasta 3 cm): 8€", "Grande (más de 4 cm): 10€"] },
        { name: "Cuchillas con punta de flecha (2 biseles en el mismo plano)", prices: ["Pequeñas (hasta cm):", "Grandes (más de cm):"] }
      ]
    },
    {
      title: "Servicios de Restauración y Reparación de Cuchillos",
      items: [
        { name: "Reparación de puntas rotas", prices: ["Pequeñas roturas: 4€", "Roturas grandes o complicadas: 6€"] },
        { name: "Eliminación de astillas en el filo", prices: ["Astillas menores (entre 2 mm y 5 mm): 4€", "Astillas grandes (más de 5 mm): 6€"] },
        { name: "Rectificación de ángulos del filo", prices: ["5€ por cuchillo"] },
        { name: "Reperfilado", prices: ["6€"] },
        { name: "Limpieza profunda y eliminación de óxido", prices: ["Limpieza básica (sin óxido significativo): 4€", "Eliminación de óxido y pulido completo: 10€"] },
        { name: "Recargos Específicos por Tiempo de Entrega", prices: ["Entrega al día siguiente: 50% adicional sobre el precio base.", "Entrega en dos días: 35% adicional sobre el precio base."] }
      ]
    },
    {
      title: "Precios para Afilado Manual de Cuchillos (no incluir)",
      items: [
        { name: "Cuchillos de Estilo Occidental (Doble Filo)", prices: ["Solo Afilado: $25 a $30", "Reparación Mayor (Incluye reparar astillas, eliminar óxido y adelgazar el cuchillo): $35 a $45"] },
        { name: "Cuchillos de Estilo Japonés (Un Solo Filo)", prices: ["Afilado y Reparación: $65 a $75"] }
      ]
    }
  ];

  return (
    <React.Fragment>
      <>
        <section className="py-2 md:py-2  bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="max-w-md lg:max-w-none mx-auto">
              <div className="max-w-3xl mb-20">
                <h1 className="font-heading text-6xl sm:text-8xl lg:text-10xl tracking-tighter">Listado de precios</h1>
              </div>
             
              <div className="flex flex-wrap -mx-4">
                {pricingItems.map((pricingItem, index) => (
                  <div key={index} className="w-full lg:w-1/3 px-4 mb-12  text-black">
                    <div className="h-full pt-12 pb-10 px-8 border rounded-3xl bg-gray-50">
                      <span className="block mb-12 text-xl font-medium">{pricingItem.title}</span>
                      <ul>
                        {pricingItem.items.map((item, idx) => (
                          <li key={idx} className="flex flex-col mb-4">
                            <span className="font-semibold flex items-center">
                            <div className=" mr-2 ">
                            
                            <svg width={8} height={12} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <rect y="0.5" width={21} height={21} rx="10.5" fill="black" />
                            <path d="M15.7676 8.13463C15.4582 7.82482 14.9558 7.82501 14.646 8.13463L9.59787 13.183L7.35419 10.9393C7.04438 10.6295 6.54217 10.6295 6.23236 10.9393C5.92255 11.2491 5.92255 11.7513 6.23236 12.0611L9.03684 14.8656C9.19165 15.0204 9.39464 15.098 9.59765 15.098C9.80067 15.098 10.0039 15.0206 10.1587 14.8656L15.7676 9.25644C16.0775 8.94684 16.0775 8.44443 15.7676 8.13463Z" fill="black" />
                          </svg>
                            </div>
                            
                            
                            <p className="font-bold">  {item.name} </p> </span>

                            <div className="  ml-4 mt-4 mb-8"> 
                            {item.prices.map((price, idx) => (
                              <span key={idx} className="">{price}</span>
                            ))}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
