import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionServices2 from '../components/services/IndexSectionServices2';
import IndexSectionAboutUs6 from '../components/about-us/IndexSectionAboutUs6';
import IndexSectionContact5 from '../components/contact/IndexSectionContact5';
import IndexSectionFaq7 from '../components/faq/IndexSectionFaq7';
import IndexSectionPricing9 from '../components/pricing/IndexSectionPricing9';
import IndexSectionCta4 from '../components/cta/IndexSectionCta4';
import IndexSectionFooters8 from '../components/footers/IndexSectionFooters8';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders1 />
      <IndexSectionServices2 />
      <IndexSectionAboutUs6 />
      <IndexSectionContact5 />
      <IndexSectionFaq7 />
      <IndexSectionPricing9 />
      <IndexSectionCta4 />
      <IndexSectionFooters8 />
    </React.Fragment>
  );
}

