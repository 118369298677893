import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

export default function IndexSectionCta4() {
  return (
    <React.Fragment>
      <>
        <section className="py-12  bg-black md:py-24 border-b border-gray-600">
          <div className="container mx-auto px-4">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <div className="flex flex-wrap -mx-4 items-center">
                <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                  <div className="max-w-lg xl:max-w-none">
                    <span className="block mb-4 text-sm text-white">¿Preguntas? Estamos aquí para ayudarte"</span>
                    <h1 className="font-heading text-5xl md:text-6xl 2xl:text-8xl text-white tracking-tighter mb-8">¿Listo para experimentar la precisión en cada corte?</h1>
                    <p className="xl:text-lg text-white"> No importa tu oficio: cocina, carpintería o bricolaje; estamos aquí para asegurar que tus herramientas estén en la condición óptima. Llámanos o envíanos un correo electrónico y descubre la diferencia de un afilado experto.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                  <div className="flex flex-col sm:flex-row flex-wrap lg:justify-end">
                    <a className="group inline-flex mb-3 sm:mb-0 sm:mr-6 py-5 px-8 text-black hover:text-white leading-none items-center justify-center bg-white hover:bg-blue-500 rounded-full transition duration-150" href="#">
                      <span className="mr-2.5">Llámanos</span>
                      <span className="group-hover:rotate-45 transform transition duration-75">
                        <svg width={10} height={11} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 1.5L1 9.5" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 8.83571V1.5H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </a>
                    <Link className="group inline-flex py-5 px-8 text-white hover:text-black leading-none items-center justify-center border-1.5 border-white hover:bg-white rounded-full transition duration-150"  to="contactus" smooth={true} duration={1000}>
                      <span className="mr-2.5">Contato</span>
                      <span className="group-hover:rotate-45 transform transition duration-75">
                        <svg width={10} height={11} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 1.5L1 9.5" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 8.83571V1.5H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

