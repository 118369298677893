import React from 'react';

export default function IndexSectionServices2() {
  return (
    <React.Fragment>
      <>
        <section id="services" className=" py-12 bg-gray-50 md:py-24">
          <div className="container mx-auto px-4">
            <div className="max-w-md mx-auto lg:max-w-none">
              <h1 className="font-heading text-6xl md:text-10xl tracking-tighter mb-20">Servicios</h1>
              <a className="relative mb-4 group block h-full w-full px-9 py-14 hover:bg-black border rounded-3xl transition duration-300" href="#">
                <span className="hidden group-hover:block absolute top-0 right-0 mt-16 mr-8">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 2L2 22" stroke="white" strokeWidth="3.25" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 20.3393V2H3.66071" stroke="white" strokeWidth="3.25" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <div className="rounded-3xl">
                  <div className="max-w-xs pr-10">
                    <h5 className="text-3xl xs:text-4xl group-hover:text-white mb-6">Restauración </h5>
                  </div>
                  <p className="group-hover:text-white">Restauramos incluso los filos más dañados devolviendo a tus herramientas su funcionalidad y rendimiento.</p>
                </div>
              </a>

              <a className="relative group block h-full w-full px-9 py-14 hover:bg-black border rounded-3xl transition duration-300" href="#">
                <span className="hidden group-hover:block absolute top-0 right-0 mt-16 mr-8">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 2L2 22" stroke="white" strokeWidth="3.25" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 20.3393V2H3.66071" stroke="white" strokeWidth="3.25" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <div>
                  <div className="max-w-xs pr-10">
                    <h5 className="text-3xl xs:text-4xl group-hover:text-white mb-6">Afilado</h5>
                  </div>
                  <p className="group-hover:text-white">Esoecializados en técnicas al agua  que ayudan a proteger los filos de tus herraientas, convervando asi su dureza y durabilidad.</p>
                </div>
              </a><div className="flex flex-wrap -mx-3">
                <div className="w-full lg:w-1/3 px-3 mb-6 lg:mb-0">
                </div>
                <div className="w-full lg:w-1/3 px-3 mb-6 lg:mb-0">
                </div>
              </div>
            </div>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

