import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './faq.css'; // You'll need to create this CSS file for the transitions

const faqs = [
  {
    question: "¿Cuál es la diferencia entre afilar y amolar?",
    answer: "Aunque a menudo se usan indistintamente, afilar se refiere a crear o mantener un filo fino en la herramienta o cuchillo, mientras que amolar implica remover material para dar forma o restaurar un filo muy desgastado. Afilamos para refinar el filo y amolamos para reparar y dar forma."
  },
  {
    question: "¿Por qué es importante mantener bien afiladas mis herramientas y cuchillos?",
    answer: "Herramientas y cuchillos bien afilados son más seguros, eficaces y fáciles de usar. Un buen afilado reduce la necesidad de aplicar fuerza excesiva durante el corte, lo que a su vez minimiza el riesgo de accidentes y mejora la precisión en el trabajo."
  },
  {
    question: "¿Afilados Rosa utiliza técnicas manuales o máquinas para afilar?",
    answer: "En Afilados Rosa, combinamos técnicas manuales tradicionales con tecnología moderna para garantizar un afilado preciso y efectivo. Utilizamos piedras de afilar japonesas, ruedas de CBN, y máquinas de afilado Tormek, dependiendo de la naturaleza y requerimientos específicos de cada herramienta."
  },
  {
    question: "¿Es posible afilar cualquier tipo de metal?",
    answer: "Casi todos los metales comunes utilizados en herramientas y cuchillos pueden ser afilados. Sin embargo, algunos metales como el titanio requieren técnicas y equipos especiales. Si tienes una herramienta de un material inusual, consúltanos para ver cómo podemos ayudarte."
  },
  {
    question: "¿Qué puedo hacer si mi herramienta está extremadamente desgastada o dañada?",
    answer: "En Afilados Rosa, ofrecemos servicios de restauración y reparación además de nuestro servicio de afilado estándar. Podemos evaluar el daño y recomendar la mejor solución, ya sea afilado, amolado, o incluso reemplazo si la herramienta no puede ser reparada eficientemente."
  },
  {
    question: "¿Cuánto tiempo tarda el servicio de afilado?",
    answer: "El tiempo necesario para completar el servicio de afilado puede variar significativamente dependiendo del tipo y la condición de la herramienta o cuchillo, así como de la demanda de servicios en ese momento. Por lo general, el proceso de afilado puede tomar una semana para trabajos estándar bajo condiciones normales de trabajo. Sin embargo, en períodos de alta demanda o para servicios de reparación o restauración más complejos, este tiempo puede extenderse. Siempre proporcionamos un estimado de tiempo más preciso cuando recibimos tu herramienta y evaluamos el trabajo necesario. También contamos con que algunos encargos son de alta prioridad por lo que disponemos de tarifas para encargos rápidos."
  },
  {
    question: "¿Afilados Rosa ofrece servicios para clientes comerciales o industriales?",
    answer: "Sí, ofrecemos servicios tanto para clientes residenciales como comerciales e industriales. Contamos con la capacidad de manejar grandes volúmenes de herramientas y ofrecemos contratos de servicio para mantenimiento regular a empresas y profesionales que dependen de herramientas afiladas para su operación."
  },
  {
    question: "¿Afilan cuchillos serrados?",
    answer: "Lamentablemente, nuestro centro de servicio técnico no cuenta con el equipo necesario para afilar correctamente cuchillos serrados.\n\nDescripción: 'Contáctanos a través de tu método preferido y te responderemos lo antes posible.'\nOpciones de Contacto:\nCorreo Electrónico: Un ícono con el email.\nWhatsApp: Un ícono con un enlace para iniciar una conversación en WhatsApp.\nTeléfono: Un ícono con el número telefónico"
  }
];

export default function IndexSectionFaq7() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <React.Fragment>
      <>
        <section id="faqs" className="py-12 bg-gray-50 md:py-24">
          <div className="container mx-auto px-4">
            <h1 className="font-heading text-6xl sm:text-8xl md:text-10xl tracking-tighter mb-12">Preguntas frecuentes</h1>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-8 mb-6 lg:mb-0">
                <div className="lg:max-w-xl">
                  {faqs.slice(0, 4).map((faq, index) => (
                    <div key={index} className="pb-6 py-4 mb-6  px-0 border-b border-black">
                      <div className="flex w-full justify-between items-center">
                        <h5 className="text-xl xs:text-2xl tracking-tight">{faq.question}</h5>
                        <button
                          onClick={() => handleToggle(index)}
                          className="flex-shrink-0 inline-flex ml-4 items-center justify-center w-12 h-12 text-black hover:text-white border border-black hover:bg-black rounded-full transition duration-150"
                        >
                          <svg
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d={expandedIndex === index ? "M13.459 7.5107L9.21179 3.2635L4.9646 7.5107" : "M13.459 10.4893L9.21179 14.7365L4.9646 10.4893"}
                              stroke="currentColor"
                              strokeWidth="1.04955"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.21094 2.84152L9.21094 14.6175"
                              stroke="currentColor"
                              strokeWidth="1.04955"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <CSSTransition
                        in={expandedIndex === index}
                        timeout={300}
                        classNames="faq"
                        unmountOnExit
                      >
                        <p className="mt-4 text-base">{faq.answer}</p>
                      </CSSTransition>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="lg:ml-auto lg:max-w-xl">
                  {faqs.slice(4).map((faq, index) => (
                    <div key={index + 4} className="pb-6 py-4 mb-6 px-0 border-b border-black">
                      <div className="flex w-full justify-between items-center">
                        <h5 className="text-xl xs:text-2xl tracking-tight">{faq.question}</h5>
                        <button
                          onClick={() => handleToggle(index + 4)}
                          className="flex-shrink-0 inline-flex ml-4 items-center justify-center w-12 h-12 text-black hover:text-white border border-black hover:bg-black rounded-full transition duration-150"
                        >
                          <svg
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d={expandedIndex === index + 4 ? "M13.459 7.5107L9.21179 3.2635L4.9646 7.5107" : "M13.459 10.4893L9.21179 14.7365L4.9646 10.4893"}
                              stroke="currentColor"
                              strokeWidth="1.04955"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.21094 2.84152L9.21094 14.6175"
                              stroke="currentColor"
                              strokeWidth="1.04955"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <CSSTransition
                        in={expandedIndex === index + 4}
                        timeout={300}
                        classNames="faq"
                        unmountOnExit
                      >
                        <p className="mt-4 text-base">{faq.answer}</p>
                      </CSSTransition>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
